<template>
  <b-container>
    <b-jumbotron>
      <template #header>Program Afiliasi Analitica</template>
      <template #lead>#AksiNyata</template>
      <hr class="my-4" />
    </b-jumbotron>
    <ComissionTable v-if="selfAffiliate" :affiliate="selfAffiliate" />
  </b-container>
</template>

<script>
import ComissionTable from "../components/ComissionTable.vue";
import { types as affiliate_module } from "../store/affiliate.module";
import { auth } from "../plugins/firebase";

export default {
  name: "Home",
  components: {
    ComissionTable,
  },
  computed: {
    selfAffiliate() {
      return this.$store.state.affiliate.data[auth.currentUser.uid];
    },
  },
  created() {
    this.$store.dispatch(affiliate_module.INIT_LISTENER_SINGLE, {
      name: "self",
      id: auth.currentUser.uid,
    });
  },
};
</script>

import { db } from "../plugins/firebase";

const userCollectionRef = () => db.collection(`users`);
const purchaseCollectionRef = () => db.collection(`purchases`);
const affiliateCollectionRef = (source) =>
  db.collection(`sources/${source}/affiliates`);
const productUserCollectionRef = (source) =>
  db.collection(`sources/${source}/productUsers`);

export {
  userCollectionRef,
  purchaseCollectionRef,
  affiliateCollectionRef,
  productUserCollectionRef,
};

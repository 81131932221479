import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import Comission from "@/views/Comission.vue";
import Account from "@/views/Account.vue";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/account",
    name: "account",
    component: Account,
  },
  {
    path: "/affiliate",
    name: "Afiliasi",
    component: Comission,
    meta: {
      authRequired: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    if (store.state.auth.isAuthenticated) {
      next();
    } else {
      alert("You must be logged in to see this page");
      next({
        path: "/account",
      });
    }
  } else {
    next();
  }
});

export default router;

import Vue from "vue";
import { affiliateCollectionRef } from "../resources/firebase";

// action types
export const INIT_LISTENER_SINGLE = "initAffiliateSingleListener";
export const INIT_LISTENER = "initAffiliateListener";

// mutation types
export const SET_LISTENER = "setAffiliateListener";
export const PURGE_LISTENER = "deleteAffiliateListener";
export const SET_DATA = "setAffiliateData";
export const PURGE_DATA = "deleteAffiliateData";

export const types = {
  INIT_LISTENER_SINGLE,
  INIT_LISTENER,
  SET_LISTENER,
  PURGE_LISTENER,
  SET_DATA,
  PURGE_DATA,
};

const state = {
  listener: {},
  data: {},
};

const getters = {};

const actions = {
  [INIT_LISTENER_SINGLE](context, { name, id }) {
    if (context.state.listener[name] === undefined) {
      context.commit(SET_LISTENER, {
        name: name,
        listener: affiliateCollectionRef("analitica")
          .doc(id)
          .onSnapshot(
            (doc) => {
              context.commit(SET_DATA, {
                id: doc.id,
                hasPendingWrites: doc.metadata.hasPendingWrites,
                ...doc.data(),
              });
            },
            (error) => {
              alert(error);
              console.log("error", INIT_LISTENER, error);
            },
            () => context.commit(PURGE_LISTENER, name)
          ),
      });
      return true;
    }
    return false;
  },
  [INIT_LISTENER](context, { name, queries }) {
    if (context.state.listener[name] === undefined) {
      let ref = affiliateCollectionRef();
      queries.forEach((query) => {
        ref = ref.where(query[0], query[1], query[2]);
      });
      context.commit(SET_LISTENER, {
        name: name,
        listener: ref.onSnapshot(
          (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (context.state.data[doc.id] == undefined) {
                context.commit(SET_DATA, {
                  id: doc.id,
                  hasPendingWrites: doc.metadata.hasPendingWrites,
                  ...doc.data(),
                });
              }
            });
            querySnapshot.docChanges().forEach((change) => {
              if (context.state.data[change.doc.id] != undefined) {
                const affiliate = {
                  id: change.doc.id,
                  hasPendingWrites: change.doc.metadata.hasPendingWrites,
                  ...change.doc.data(),
                };
                if (change.type === "added") {
                  context.commit(SET_DATA, affiliate);
                }
                if (change.type === "modified") {
                  context.commit(SET_DATA, affiliate);
                }
                if (change.type === "removed") {
                  context.commit(PURGE_DATA, affiliate.id);
                }
              }
            });
          },
          (error) => {
            alert(error);
            console.log("error", INIT_LISTENER, error);
          },
          () => context.commit(PURGE_LISTENER, name)
        ),
      });
      return true;
    }
    return false;
  },
};

const mutations = {
  [SET_LISTENER](state, { name, listener }) {
    Vue.set(state.listener, name, listener);
  },
  [PURGE_LISTENER](state, name) {
    Vue.delete(state.listener, name);
  },
  [SET_DATA](state, affiliate) {
    Vue.set(state.data, affiliate.id, affiliate);
  },
  [PURGE_DATA](state, id) {
    Vue.delete(state.data, id);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

import Vue from "vue";
import { purchaseCollectionRef } from "../resources/firebase";

// action types
export const INIT_LISTENER_SINGLE = "initPurchaseSingleListener";
export const INIT_LISTENER = "initPurchaseListener";

// mutation types
export const SET_LOADING = "setPurchaseLoading";
export const SET_LISTENER = "setPurchaseListener";
export const PURGE_LISTENER = "deletePurchaseListener";
export const SET_DATA = "setPurchaseData";
export const PURGE_DATA = "deletePurchaseData";

export const types = {
  INIT_LISTENER_SINGLE,
  INIT_LISTENER,
  SET_LOADING,
  SET_LISTENER,
  PURGE_LISTENER,
  SET_DATA,
  PURGE_DATA,
};

const state = {
  listener: {},
  data: {},
  loading: false,
};

const getters = {
  listeners(state) {
    return Object.keys(state.listener);
  },
};

const actions = {
  [INIT_LISTENER_SINGLE](context, { name, id }) {
    if (context.state.listener[name] === undefined) {
      context.commit(SET_LOADING, true);
      context.commit(SET_LISTENER, {
        name: name,
        listener: purchaseCollectionRef()
          .doc(id)
          .onSnapshot(
            (doc) => {
              context.commit(SET_LOADING, false);
              context.commit(SET_DATA, {
                id: doc.id,
                hasPendingWrites: doc.metadata.hasPendingWrites,
                ...doc.data(),
              });
            },
            (error) => {
              alert(error);
              console.log("error", INIT_LISTENER, error);
            },
            () => context.commit(PURGE_LISTENER, name)
          ),
      });
      return true;
    }
    return false;
  },
  [INIT_LISTENER](context, { name, queries }) {
    if (context.state.listener[name] === undefined) {
      context.commit(SET_LOADING, true);
      let ref = purchaseCollectionRef();
      queries.forEach((query) => {
        ref = ref.where(query[0], query[1], query[2]);
      });
      context.commit(SET_LISTENER, {
        name: name,
        listener: ref.orderBy("date.success", "desc").onSnapshot(
          (querySnapshot) => {
            context.commit(SET_LOADING, false);
            querySnapshot.forEach((doc) => {
              if (context.state.data[doc.id] == undefined) {
                context.commit(SET_DATA, {
                  id: doc.id,
                  hasPendingWrites: doc.metadata.hasPendingWrites,
                  ...doc.data(),
                });
              }
            });
            querySnapshot.docChanges().forEach((change) => {
              if (context.state.data[change.doc.id] != undefined) {
                const purchase = {
                  id: change.doc.id,
                  hasPendingWrites: change.doc.metadata.hasPendingWrites,
                  ...change.doc.data(),
                };
                if (change.type === "added") {
                  context.commit(SET_DATA, purchase);
                }
                if (change.type === "modified") {
                  context.commit(SET_DATA, purchase);
                }
                if (change.type === "removed") {
                  context.commit(PURGE_DATA, purchase.id);
                }
              }
            });
          },
          (error) => {
            alert(error);
            console.log("error", INIT_LISTENER, error);
          },
          () => context.commit(PURGE_LISTENER, name)
        ),
      });
      return true;
    }
    return false;
  },
};

const mutations = {
  [SET_LOADING](state, status) {
    state.loading = status;
  },
  [SET_LISTENER](state, { name, listener }) {
    Vue.set(state.listener, name, listener);
  },
  [PURGE_LISTENER](state, name) {
    Vue.delete(state.listener, name);
  },
  [SET_DATA](state, purchase) {
    Vue.set(state.data, purchase.id, purchase);
  },
  [PURGE_DATA](state, id) {
    Vue.delete(state.data, id);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

<template>
  <b-container>
    <div style="text-align: center">
      <img alt="Analitica logo" src="../assets/logo.jpeg" />
    </div>
    <div v-if="isAuthenticated" style="text-align: center">
      <b-button @click="OnLogout" variant="danger">Logout</b-button>
    </div>
    <div v-else>
      <Login />
    </div>
  </b-container>
</template>

<script>
import Login from "@/components/Login.vue";
export default {
  components: { Login },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.isAuthenticated;
    },
  },
  methods: {
    OnLogout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

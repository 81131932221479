import { auth } from "@/plugins/firebase";

// action types
export const INIT_AUTH = "initAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const UPDATE_PASSWORD = "updatePassword";

// mutation types
export const PURGE_AUTH = "delUser";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: null,
  isAuthenticated: false,
};

const getters = {
  // currentUser(state) {
  //   return state.user;
  // },
  // isAuthenticated(state) {
  //   return state.isAuthenticated;
  // },
};

const actions = {
  [LOGIN](context, { email, password }) {
    return new Promise((resolve) => {
      auth
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("loggedin", user.email);
          context.commit(SET_AUTH, user);
          resolve(user);
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          context.commit(SET_ERROR, {
            code: errorCode,
            message: errorMessage,
          });
        });
    });
  },
  [LOGOUT](context) {
    return auth.signOut().then(() => {
      console.log("loggedout");
      context.commit(PURGE_AUTH);
      return true;
    });
  },
  [INIT_AUTH](context) {
    return new Promise((resolve) => {
      auth.onAuthStateChanged((user) => {
        if (user && !user.isAnonymous) {
          context.commit(SET_AUTH, user);
        } else {
          context.commit(PURGE_AUTH);
        }
        resolve(user);
      });
    })
      .then((user) => user && !user.isAnonymous)
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        context.commit(SET_ERROR, {
          code: errorCode,
          message: errorMessage,
        });
      });
  },
  [UPDATE_PASSWORD](context, newPassword) {
    return auth.currentUser
      .updatePassword(newPassword)
      .then(() => {
        return true;
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        context.commit(SET_ERROR, {
          code: errorCode,
          message: errorMessage,
        });
      });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.error = {
      code: "",
      message: "",
    };
    if (error.code) state.error.code = error.code;
    if (error.message) state.error.message = error.message;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.error = null;
    console.log(state);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = null;
    state.error = null;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

<template>
  <b-card
    title="Daftar Komisi Voucher"
    sub-title="Berikut daftar komisi yang kamu terima dari referensi melalui voucher produk Analitica."
  >
    kode voucher: {{ Object.keys(this.affiliate.voucher) }}
    <b-table
      striped
      hover
      no-border-collapse
      sticky-header="450px"
      responsive
      :busy="isLoadingCommissionTable"
      :items="comissions"
      :fields="fields"
      :show-empty="!isLoadingCommissionTable && comissions.length == 0"
      empty-text="Belum ada komisi yang tersedia"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #head()="scope">
        <div class="text-nowrap">
          {{ scope.label }}
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { types as purchase_module } from "../store/purchase.module";

export default {
  props: {
    affiliate: Object,
  },
  data() {
    return {
      fields: [
        "transaksi_sukses",
        "nama_klien",
        "nama_produk",
        "harga_produk",
        "komisi",
        "voucher",
      ],
    };
  },
  computed: {
    comissions() {
      const v = this;
      return Object.values(this.$store.state.purchase.data)
        .map((purchase) => {
          const voucher =
            v.affiliate.voucher[purchase.amountDetails.voucher.code];
          if (voucher == undefined) return null;
          const commission = voucher.productCommission[purchase.prid];
          if (voucher == undefined) return null;
          return {
            transaksi_sukses: purchase.date.success
              ? purchase.date.success.toDate().toLocaleString("id-ID", {
                  weekday: "long",
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  timeZoneName: "short",
                })
              : "-",
            nama_klien: purchase.userDetails.firstName,
            nama_produk: purchase.productDetails.name,
            harga_produk: Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(purchase.productDetails.price),
            komisi: Intl.NumberFormat("id-ID", {
              style: "currency",
              currency: "IDR",
            }).format(purchase.productDetails.price * commission),
            voucher: purchase.amountDetails.voucher.code,
          };
        })
        .filter((e) => e);
    },
    isLoadingCommissionTable() {
      return this.comissions.length === 0 && this.$store.state.purchase.loading;
    },
  },
  created() {
    Object.keys(this.affiliate.voucher).forEach((voucher) => {
      const name = `comission-${voucher}`;
      const code = voucher;
      if (this.$store.state.purchase.listener[name] === undefined)
        this.$store.commit(purchase_module.SET_LOADING, true);
      this.$store.dispatch(purchase_module.INIT_LISTENER, {
        name: name,
        queries: [
          ["amountDetails.voucher.code", "==", code],
          ["date.success", "!=", null],
        ],
      });
    });
  },
};
</script>

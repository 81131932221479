<template>
  <b-form @submit="onSubmit" @reset="onReset" v-if="show">
    <b-form-group
      id="text-email"
      label="Email:"
      label-for="input-text-email"
      description="Masukkan email yang terdaftar di akun Analitica mu"
    >
      <b-form-input
        id="input-text-email"
        v-model="form.email"
        type="email"
        placeholder="Enter email"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="text-password"
      label="Password:"
      label-for="input-text-password"
      description="Password 8-20 karakter"
    >
      <b-form-input
        id="input-text-password"
        v-model="form.password"
        type="password"
        placeholder="Enter password"
        required
      ></b-form-input>
    </b-form-group>

    <b-button type="submit" variant="primary">Login</b-button>
  </b-form>
</template>

<script>
// var emailRE =
//   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      const v = this;
      console.log(v.form);
      event.preventDefault();
      this.$store
        .dispatch("login", {
          email: v.form.email,
          password: v.form.password,
        })
        .then(() => {
          this.$router.push({ name: "Afiliasi" });
        });
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
  // // computed property for form validation state
  // computed: {
  //   validation: function () {
  //     return {
  //       name: !!this.newUser.name.trim(),
  //       email: emailRE.test(this.newUser.email),
  //     };
  //   },
  //   isValid: function () {
  //     var validation = this.validation;
  //     return Object.keys(validation).every(function (key) {
  //       return validation[key];
  //     });
  //   },
  // },
  // // methods
  // methods: {
  //   addUser: function () {
  //     if (this.isValid) {
  //       usersRef.push(this.newUser);
  //       this.newUser.name = "";
  //       this.newUser.email = "";
  //     }
  //   },
  //   removeUser: function (user) {
  //     usersRef.child(user[".key"]).remove();
  //   },
  // },
};
</script>
